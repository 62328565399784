
import Vue from "vue";

export default Vue.extend({
  name: "place-vacancy-text",
  props: {
    status: {
      type: String,
      required: false,
      default: "closed",
      validator: (value: string) =>
        ["lo", "closed", "open", "vacancy", "crowded", "busy", "full", "waiting", "custom1"].includes(value),
    },
    text: { type: String, required: false, default: "-" },
  },
});
