
import Vue from "vue";

import PlaceSingle from "@/components/place/single/index.vue";
import PlaceMultiple from "@/components/place/multiple/index.vue";
import TheFooter from "@/components/the-footer.vue";
import FullscreenAlertReload from "@/components/fullscreen-alert-reload.vue";

import { mapState } from "vuex";

type DisplayMode = "single" | "multiple" | "none";

export default Vue.extend({
  name: "views-index",
  components: {
    PlaceSingle,
    PlaceMultiple,
    TheFooter,
    FullscreenAlertReload,
  },
  computed: {
    ...mapState(["placeList", "website", "alertView"]),
    hasAtLeastOnePlace(): boolean {
      return this.placeList.length > 0;
    },
    displayMode(): DisplayMode {
      if (!this.hasAtLeastOnePlace) {
        return "none";
      }
      return this.placeList.length === 1 ? "single" : "multiple";
    },
  },
  async created() {
    this.setup();
  },
  methods: {
    async setup(): Promise<void> {
      const websiteId: string | undefined = process.env.VUE_APP_WEBSITE_ID;
      if (websiteId === undefined) {
        return;
      }
      this.$store.dispatch("fetchWebsite", websiteId);
    },
  },
});
