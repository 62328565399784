
import Vue from "vue";

import TheFooterFloating from "@/components/the-footer-floating.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "app",
  components: {
    TheFooterFloating,
  },
  computed: {
    ...mapState(["website"]),
  },
  created() {
    const websiteId: string | undefined = process.env.VUE_APP_WEBSITE_ID;
    if (websiteId === undefined) {
      return;
    }
    this.$store.dispatch("fetchWebsite", websiteId);
  },
});
