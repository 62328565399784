
import Vue from "vue";

export default Vue.extend({
  name: "new-alert-view",
  props: {
    msg: { type: String, default: "通信エラー" },
    btnTitle: { type: String, default: "リロードする" },
    btnAction: { type: String, default: "reload" },
  },
  methods: {
    reloadClicked() {
      if (this.btnAction === "reload") window.location.reload();
      else if (this.btnAction === "emit") this.$emit("okClicked");
    },
  },
});
