
import Vue from "vue";

import Icon from "@/components/v/icon.vue";

export default Vue.extend({
  name: "place-panel-media",
  components: {
    Icon,
  },
  props: {
    websiteUrl: { type: String, required: false, default: "" },
    facebookUrl: { type: String, required: false, default: "" },
    twitterUrl: { type: String, required: false, default: "" },
    lineUrl: { type: String, required: false, default: "" },
    instagramUrl: { type: String, required: false, default: "" },
  },
  computed: {
    isSomeInformationExists(): boolean {
      return this.showWebsite || this.showSns;
    },
    showWebsite(): boolean {
      return this.websiteUrl.length > 0;
    },
    showSns(): boolean {
      return this.showSnsFacebook || this.showSnsTwitter || this.showSnsLine || this.showSnsInstagram;
    },
    showSnsFacebook(): boolean {
      return this.facebookUrl.length > 0;
    },
    showSnsTwitter(): boolean {
      return this.twitterUrl.length > 0;
    },
    showSnsLine(): boolean {
      return this.lineUrl.length > 0;
    },
    showSnsInstagram(): boolean {
      return this.instagramUrl.length > 0;
    },
  },
});
