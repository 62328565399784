
import Vue from "vue";

import {
  Place as BackendPlace,
  Http,
  Vacancy as BackendVacancy,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import ImageWithFallback from "@/components/image-with-fallback.vue";
import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type VacancyStatus = BackendVacancy.VacancyState;
type Equipment = BackendPlace.Equipment;

export default Vue.extend({
  name: "place-card-white-3-images",
  components: {
    ImageWithFallback,
    LabelSet,
    VacancyText,
  },
  props: {
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    hotSpring: {
      type: Object as () => Http.ResponseHotSpringValues,
      required: false,
      //default: (): undefined => undefined,
    },
    place: {
      type: Object as () => Place,
      required: false,
      default() {
        return {
          placeIdHash: "",
          name: "",
          floor: "",
          imageUrlList: [],
          openingHoursList: [],
          equipmentList: [],
          featureList: [],
        };
      },
    },
    vacancy: {
      type: Object as () => Vacancy,
      required: false,
      default() {
        return {
          placeIdHash: "",
          status: {
            state: "closed" as VacancyStatus,
            text: "-",
            countText: "-",
            appearance: {
              textColor: "#969696",
              backgroundColor: "#ffffff",
            },
          },
          qticketStatus: {
            isAcceptable: false,
            useSizeLimit: false,
          },
        };
      },
    },
  },
  computed: {
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.currentGender;
    },
  },
});
