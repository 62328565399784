import { Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

export class BaseError extends Error {
    statusCode: number;
    message: string;

    constructor(statusCode = 500, message = "Internal Error") {
        super(message);
        this.statusCode = statusCode;
        this.message = message;
    }
}

export class NotFoundError extends BaseError {
    constructor(message = "") {
        super(404, message);
    }
}

export class WebsiteNotFoundError extends NotFoundError {
    constructor(message = "") {
        super(message);
    }
}

export class ContentNotFoundError extends NotFoundError {
    constructor(message = "") {
        super(message);
    }
}

export function isWebsiteNotFoundResponseData(body: any): body is Http.NotFoundResponseData {
    if (body == null) return false;

    return body.type === "WebsiteNotFound" && typeof body.message === "string";
}

export function isContentNotFoundResponseData(body: any): body is Http.NotFoundResponseData {
    if (body == null) return false;

    return body.type === "PlaceNotFound" && typeof body.message === "string";
}
