
import Vue from "vue";

import VIcon from "@/components/v/icon.vue";

export default Vue.extend({
  name: "place-link-copy-toast",
  components: {
    VIcon,
  },
  props: {
    msec: { type: Number, default: 1000 },
  },
  mounted() {
    window.setTimeout(() => {
      this.killComponent();
    }, this.msec);
  },
  methods: {
    killComponent() {
      this.$emit("kill-toast");
    },
  },
});
