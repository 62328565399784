
import Vue from "vue";
import dayjs from "dayjs";

import { mapMutations } from "vuex";
import {
  Vacancy as BackendVacancy,
  Place as BackendPlace,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
  fetchPlaceDetailByPlaceIdHash,
  fetchVacancyListByPlaceIdHashList,
  fetchResponseHotSpringValuesByPlaceIdHashList,
} from "@/api/enterprise-vacan.adapter.api";
// components
import PlacePanelImage from "@/components/place/panel/image.vue";
import PlacePanelSummary from "@/components/place/panel/summary.vue";
import PlacePanelBasic from "@/components/place/panel/basic.vue";
import PlacePanelMedia from "@/components/place/panel/media.vue";

type PlaceDetail = BackendPlace.PlaceDetail;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

interface DataViewsPlaces {
  placeDetail: PlaceDetail | undefined;
  vacancy: Vacancy | undefined;
  hotSpringSetting: HotSpringSetting | undefined;
  timer: { fetchingVacancies: number | undefined };
  pageStatus: "ok" | "preparing" | "error";
  lastFetchedVacancyUnixTime: number;
}

export default Vue.extend({
  name: "views-single-place",
  components: {
    PlacePanelImage,
    PlacePanelSummary,
    PlacePanelBasic,
    PlacePanelMedia,
  },
  props: {
    placeIdHash: { type: String, required: true, default: undefined },
    websiteId: { type: String, required: true, default: undefined },
    questionnaireUrl: { type: String, required: false, default: undefined },
  },
  data(): DataViewsPlaces {
    return {
      placeDetail: undefined,
      vacancy: undefined,
      hotSpringSetting: undefined,
      timer: { fetchingVacancies: undefined },
      pageStatus: "preparing",
      lastFetchedVacancyUnixTime: dayjs().unix(),
    };
  },
  computed: {
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.currentGender;
    },
  },
  async mounted() {
    this.setup();
  },
  async destroyed() {
    await this.teardown();
  },
  methods: {
    ...mapMutations(["setViewType"]),
    async setup(): Promise<void> {
      this.pageStatus = "preparing";
      await fetchPlaceDetailByPlaceIdHash(this.websiteId, this.placeIdHash)
        .then(async (placeDetail: PlaceDetail) => {
          this.$store.dispatch("updatePlace", placeDetail);
          this.placeDetail = placeDetail;
          this.pageStatus = "ok";
          await this.fetchHotSrpingSetting();
          await this.fetchVacancy();
          this.loop();
        })
        .catch(() => {
          this.pageStatus = "error";
        });
    },
    loop(): void {
      this.timer.fetchingVacancies = setInterval(async () => {
        await this.fetchVacancy().catch(() => {
          this.pageStatus = "error";
          this.teardown();
        });
      }, 30000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearInterval(this.timer.fetchingVacancies);
      }
    },
    async fetchVacancy(): Promise<void> {
      try {
        const vacancyList: Vacancy[] = await fetchVacancyListByPlaceIdHashList([this.placeIdHash]);
        this.lastFetchedVacancyUnixTime = dayjs().unix();
        this.$store.commit("setAlertView", {
          status: false,
        });

        const vacancy: Vacancy | undefined = vacancyList.shift();
        if (vacancy !== undefined) {
          this.vacancy = vacancy;
        }
      } catch {
        const NETWORK_ERROR_THRESHOLD = 3 * 60;
        const currentUnixTime = dayjs().unix();

        if (currentUnixTime - NETWORK_ERROR_THRESHOLD >= this.lastFetchedVacancyUnixTime) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    async fetchHotSrpingSetting(): Promise<void> {
      const hotSpringSettingList = await fetchResponseHotSpringValuesByPlaceIdHashList([this.placeIdHash]);
      const hotSpringSetting: HotSpringSetting | undefined = hotSpringSettingList?.shift();
      if (hotSpringSetting !== undefined) {
        this.hotSpringSetting = hotSpringSetting;
      }
    },
  },
});
