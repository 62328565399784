
import Vue from "vue";
import { Hooper, Slide } from "hooper";

import VButton from "@/components/v/button.vue";

import { Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { ContentNotFoundError, WebsiteNotFoundError } from "@/plugins/errors";
import router from "@/router";

type PlaceDetail = Place.PlaceDetail;

interface DataViewsImageGallery {
  placeDetail?: PlaceDetail;
  pageStatus: "ok" | "preparing" | "error" | "closed";
  initialSlide?: number;
  currentSlide?: number;
  hooperSettings: Record<string, boolean>;
  websiteId: string;
}

export default Vue.extend({
  name: "views-image-gallery",
  components: {
    Hooper,
    Slide,
    VButton,
  },
  props: {
    placeIdHash: { type: String, required: true },
  },
  data(): DataViewsImageGallery {
    return {
      pageStatus: "preparing",
      placeDetail: undefined,
      initialSlide: undefined,
      currentSlide: undefined,
      hooperSettings: {
        infiniteScroll: false,
        wheelControl: false,
        keysControl: false,
      },
      websiteId: process.env.VUE_APP_WEBSITE_ID,
    };
  },
  computed: {
    srcList(): string[] {
      return this.placeDetail?.imageUrlList || [];
    },
  },
  async mounted() {
    const manifestTag = document.querySelector("link[rel=manifest]");
    manifestTag?.setAttribute("href", `/api/v1/websites/${this.$route.params.websiteId}/manifests`);
    this.setup();
  },
  methods: {
    async setup(): Promise<void> {
      this.pageStatus = "preparing";

      this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);

      const promise = this.placeDetail
        ? Promise.resolve()
        : this.$store
            .dispatch("fetchPlaceDetail", { websiteId: this.websiteId, placeIdHash: this.placeIdHash })
            .then(() => {
              this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);
            });

      await promise
        .then(async () => {
          if (this.placeDetail?.isAvailable === false) {
            this.pageStatus = "closed";
            return;
          }
          this.pageStatus = "ok";
        })
        .catch((error) => {
          if (error instanceof ContentNotFoundError) {
            router.replace({ name: "WebsiteError" });
            return;
          } else if (error instanceof WebsiteNotFoundError) {
            router.replace({ name: "Error" });
            return;
          }
          this.pageStatus = "error";
        });

      if (typeof this.$route.query.slides === "string" || typeof this.$route.query.slides === "number") {
        this.initialSlide = parseInt(this.$route.query.slides, 10);
      }
    },
    showImage(index: number) {
      this.initialSlide = this.currentSlide = index;
    },
    hideImage() {
      this.initialSlide = this.currentSlide = undefined;
    },
    slideTo({ currentSlide }: { currentSlide: number }) {
      this.currentSlide = currentSlide;
    },
  },
});
