import { render, staticRenderFns } from "./vacancy-text.vue?vue&type=template&id=19aba160&scoped=true&"
import script from "./vacancy-text.vue?vue&type=script&lang=ts&"
export * from "./vacancy-text.vue?vue&type=script&lang=ts&"
import style0 from "./vacancy-text.vue?vue&type=style&index=0&id=19aba160&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19aba160",
  null
  
)

export default component.exports