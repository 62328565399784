
import Vue from "vue";

import PoweredByVacan from "@/components/v/powered-by-vacan.vue";

export default Vue.extend({
  name: "the-footer",
  components: {
    PoweredByVacan,
  },
  props: {
    showTerms: { type: Boolean, default: false },
    showLogo: { type: Boolean, default: false },
  },
});
