
import Vue from "vue";

export default Vue.extend({
  name: "place-navi-button",
  props: {
    lat: { type: Number, required: true },
    lng: { type: Number, required: true },
  },
  methods: {
    navClicked() {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${this.lat},${this.lng}`;
      window.open(url);
    },
  },
});
