import { render, staticRenderFns } from "./link-copy-button.vue?vue&type=template&id=440c471a&scoped=true&"
import script from "./link-copy-button.vue?vue&type=script&lang=ts&"
export * from "./link-copy-button.vue?vue&type=script&lang=ts&"
import style0 from "./link-copy-button.vue?vue&type=style&index=0&id=440c471a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440c471a",
  null
  
)

export default component.exports