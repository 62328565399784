
import Vue from "vue";

import { Place as BackendPlace, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import Icon from "@/components/v/icon.vue";

type Equipment = BackendPlace.Equipment;
type EquipmentType = BackendPlace.EquipmentType;
type HotSpringSettingValue = Http.HotSpringSettingValue;

export default Vue.extend({
  name: "place-label-set",
  components: {
    Icon,
  },
  props: {
    placeIdHash: { type: String, required: true },
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    hotSpringOpeningHoursList: {
      type: Array as () => HotSpringSettingValue[],
      required: false,
      default: (): HotSpringSettingValue[] => [],
    },
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    currentGender: { type: String, required: false, default: undefined },
    isDisplayBusinessHour: { type: Boolean, default: true },
  },
  computed: {
    showLabelSet(): boolean {
      return this.showLine1 || this.showLine2;
    },
    showLine1(): boolean {
      return this.showOpeningHours || this.showHotSpringHours;
    },
    showOpeningHours(): boolean {
      return (
        this.isDisplayBusinessHour && this.openingHoursList.length > 0 && this.hotSpringOpeningHoursList.length === 0
      );
    },
    showHotSpringHours(): boolean {
      return this.isDisplayBusinessHour && this.hotSpringOpeningHoursList.length > 0;
    },
    showLine2(): boolean {
      return this.equipmentList.length > 0;
    },
    showEquipmentByType(): (equipmentType: EquipmentType) => boolean {
      return (equipmentType: EquipmentType) => {
        return this.findEquipmentValue(equipmentType) !== undefined;
      };
    },
    findEquipmentValue(): (equipmentType: EquipmentType) => string | undefined {
      return (equipmentType: EquipmentType) => {
        const equipment: Equipment | undefined = this.equipmentList.find(
          (equipment: Equipment) => equipment.equipmentType === equipmentType,
        );
        return equipment !== undefined ? equipment.displayText : undefined;
      };
    },
    translateGender(): (targetGender: string) => string {
      return (targetGender: string) => {
        if (targetGender === undefined) {
          return "";
        }
        return this.$i18n.t(`place.gender.${targetGender}`) as string;
      };
    },
  },
});
