import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueGtag from "vue-gtag";
import i18n from "@/plugins/i18n";
import VueLazyload from "vue-lazyload";
import { ContentNotFoundError, NotFoundError, WebsiteNotFoundError } from "./plugins/errors";

Vue.config.productionTip = process.env.NODE_ENV !== "production";

// gtag settings
Vue.use(VueGtag, { config: { id: process.env.VUE_APP_GTAG_MEASUREMENT_ID } }, router);

// Lazyload settings
Vue.use(VueLazyload, { lazyComponent: true });

Vue.config.errorHandler = (error) => {
    if (error instanceof ContentNotFoundError) {
        router.replace({ name: "WebsiteError" });
        return;
    }

    if (error instanceof WebsiteNotFoundError) {
        router.replace({ name: "Error" });
        return;
    }

    if (error instanceof NotFoundError) {
        router.replace({ name: "Error" });
        return;
    }
};

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
