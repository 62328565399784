const placeNumberByHash = (hash: string): number => {
    switch (hash) {
        case "vX25NanQ": // prod/stg
            return 1;
        case "Bndo8Arl":
            return 2;
        case "pny67DXP":
            return 3;
        case "gn1PZBrA":
            return 4;
        case "pXGPKG9M":
            return 5;
        case "4r6RZBrJ":
            return 6;
        case "vrE4jd9V":
            return 7;
        case "k9oYgbng":
            return 8;
        case "0XQw7Lr5":
            return 9;
        case "NXgAjgrA":
            return 10;
        default:
            return 0;
    }
};

export const locationByPlaceIdHash = (placeIdHash: string): { lat: number; lng: number } | undefined => {
    const placeNumber: number = placeNumberByHash(placeIdHash);
    switch (placeNumber) {
        case 1:
            return { lat: 36.75557, lng: 139.59969 };
        case 2:
            return { lat: 36.75640618984224, lng: 139.6019700503311 };
        case 3:
            return { lat: 36.75750568647501, lng: 139.5974859861725 };
        case 4:
            return { lat: 36.75615, lng: 139.60168 };
        case 5:
            return { lat: 36.75380513673503, lng: 139.59935509522836 };
        case 6:
            return { lat: 36.753347198371614, lng: 139.5993748134235 };
        case 7:
            return { lat: 36.75523917880809, lng: 139.60502029134344 };
        case 8:
            return { lat: 36.74916, lng: 139.6098 };
        case 9:
            return { lat: 36.74949, lng: 139.6103 };
        case 10:
            return { lat: 36.75298, lng: 139.61198 };
        default:
            return;
    }
};

// type definitions for IE11 browser
declare global {
    interface Document {
        documentMode?: unknown;
    }

    interface Window {
        MSInputMethodContext: unknown;
    }
}

export function isIE11(): boolean {
    return !!window.MSInputMethodContext && !!window.document.documentMode;
}
