
import Vue from "vue";
import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import PlaceCard from "@/components/place/card.vue";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

export default Vue.extend({
  name: "place-container",
  components: {
    PlaceCard,
  },
  props: {
    placeList: { type: Array as () => Place[], required: true, default: (): Place[] => [] },
    vacancyList: { type: Array as () => Vacancy[], required: true, default: (): Vacancy[] => [] },
    hotSpringSettingList: {
      type: Array as () => HotSpringSetting[],
      required: true,
      default: (): HotSpringSetting[] => [],
    },
  },
  computed: {
    vacancyByPlaceIdHash(): (placeIdHash: string) => Vacancy | undefined {
      return (placeIdHash: string) => this.vacancyList.find((vacancy: Vacancy) => vacancy.placeIdHash === placeIdHash);
    },
    hotSpringSettingByPlaceIdHash(): (placeIdHash: string) => HotSpringSetting | undefined {
      return (placeIdHash: string) =>
        this.hotSpringSettingList.find(
          (hotSpringSetting: HotSpringSetting) => hotSpringSetting.placeIdHash === placeIdHash,
        );
    },
  },
});
