
import Vue from "vue";
import dayjs from "dayjs";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { fetchVacancyListByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
import { fetchResponseHotSpringValuesByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
// components
import TheHeader from "@/components/the-header.vue";
import PlaceContainer from "@/components/place/container.vue";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotStringSetting = Http.ResponseHotSpringValues;

interface DataPlaceMultipleIndex {
  vacancyList: Vacancy[];
  timer: { fetchingVacancies: number | undefined };
  hotSpringSettingList: HotStringSetting[];
  lastFetchedVacancyUnixTime: number;
}

type DisplayMode = "single" | "multiple" | "none";

export default Vue.extend({
  name: "views-multiple-places",
  components: {
    TheHeader,
    PlaceContainer,
  },
  props: {
    placeList: { type: Array as () => Place[], default: () => [] },
    website: {
      type: Object as () => { id: string; name: string; questionnaireUrl?: string },
      default: (): { id: string; name: string; questionnaireUrl?: string } => ({
        id: "",
        name: "",
        questionnaireUrl: undefined,
      }),
    },
  },
  data(): DataPlaceMultipleIndex {
    return {
      vacancyList: [],
      timer: { fetchingVacancies: undefined },
      hotSpringSettingList: [],
      lastFetchedVacancyUnixTime: dayjs().unix(),
    };
  },
  computed: {
    placeIdHashList(): string[] {
      return this.placeList.map((place: Place) => place.placeIdHash);
    },
    vacancyByPlaceIdHash(): (placeIdHash: string) => Vacancy | undefined {
      return (placeIdHash: string) => this.vacancyList.find((vacancy: Vacancy) => vacancy.placeIdHash === placeIdHash);
    },
    hasAtLeastOnePlace(): boolean {
      return this.placeList.length > 0 && this.vacancyList.length > 0;
    },
    displayMode(): DisplayMode {
      if (!this.hasAtLeastOnePlace) {
        return "none";
      }
      return this.placeList.length === 1 ? "single" : "multiple";
    },
  },
  async created() {
    await this.setup();
    this.loop();
  },
  destroyed() {
    this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      try {
        this.vacancyList = await fetchVacancyListByPlaceIdHashList(this.placeIdHashList);
        this.lastFetchedVacancyUnixTime = dayjs().unix();
        this.$store.commit("setAlertView", {
          status: false,
        });
        this.hotSpringSettingList = (await fetchResponseHotSpringValuesByPlaceIdHashList(this.placeIdHashList)) ?? [];
      } catch {
        const NETWORK_ERROR_THRESHOLD = 3 * 60;
        const currentUnixTime = dayjs().unix();

        if (currentUnixTime >= this.lastFetchedVacancyUnixTime + NETWORK_ERROR_THRESHOLD) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    loop(): void {
      this.timer.fetchingVacancies = setTimeout(async () => {
        await this.setup();
        this.loop();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearTimeout(this.timer.fetchingVacancies);
      }
    },
  },
});
