
import Vue from "vue";

export default Vue.extend({
  name: "place-link-copy-button",
  methods: {
    copyURLtoClipboard() {
      const text = window.location.href;
      if (!navigator.clipboard) {
        // ie11
        if (this.fallbackCopyTextToClipboard(text)) {
          this.$emit("link-copied");
        }
        return;
      }

      navigator.clipboard.writeText(text).then(
        () => {
          this.$emit("link-copied");
        },
        () => {
          console.error("Cannot copy to clipboard..");
        },
      );
    },
    fallbackCopyTextToClipboard(text: string): boolean {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        return document.execCommand("copy");
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        return false;
      }
    },
  },
});
