
import Vue from "vue";
import dayjs from "dayjs";

import {
  Vacancy as BackendVacancy,
  Place as BackendPlace,
  Http,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
  fetchVacancyListByPlaceIdHashList,
  fetchResponseHotSpringValuesByPlaceIdHashList,
} from "@/api/enterprise-vacan.adapter.api";

// components
import PlacePanelImage from "@/components/place/panel/image.vue";
import PlacePanelSummary from "@/components/place/panel/summary.vue";
import PlacePanelBasic from "@/components/place/panel/basic.vue";
import PlacePanelMedia from "@/components/place/panel/media.vue";
import TheFooter from "@/components/the-footer.vue";
import FullscreenAlertReload from "@/components/fullscreen-alert-reload.vue";

import { ContentNotFoundError, WebsiteNotFoundError } from "@/plugins/errors";
import { mapState } from "vuex";
import ClosePageButton from "@/components/place/close-page-button.vue";

type PlaceDetail = BackendPlace.PlaceDetail;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

interface DataViewsPlaces {
  placeDetail: PlaceDetail | undefined;
  vacancy: Vacancy | undefined;
  hotSpringSetting: HotSpringSetting | undefined;
  timer: { fetchingVacancies: number | undefined; tryingSetup: number | undefined };
  pageStatus: "ok" | "preparing" | "closed" | "error";
  websiteId: string;
  lastFetchedVacancyUnixTime: number;
}

export default Vue.extend({
  name: "views-places",
  components: {
    PlacePanelImage,
    PlacePanelSummary,
    PlacePanelBasic,
    PlacePanelMedia,
    TheFooter,
    FullscreenAlertReload,
    ClosePageButton,
  },
  props: {
    placeIdHash: { type: String, required: true },
  },
  data(): DataViewsPlaces {
    return {
      placeDetail: undefined,
      vacancy: undefined,
      hotSpringSetting: undefined,
      timer: { fetchingVacancies: undefined, tryingSetup: undefined },
      pageStatus: "preparing",
      websiteId: process.env.VUE_APP_WEBSITE_ID,
      lastFetchedVacancyUnixTime: dayjs().unix(),
    };
  },
  computed: {
    ...mapState(["alertView"]),
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.currentGender;
    },
  },
  async mounted() {
    this.setup();
  },
  async destroyed() {
    await this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);

      const promise = this.placeDetail
        ? Promise.resolve()
        : this.$store
            .dispatch("fetchPlaceDetail", { websiteId: this.websiteId, placeIdHash: this.placeIdHash })
            .then(() => {
              this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);
            });

      await promise
        .then(async () => {
          if (this.placeDetail?.isAvailable === false) {
            this.pageStatus = "closed";
            return;
          }

          this.pageStatus = "ok";
          await this.fetchHotSpringSetting();
          await this.fetchVacancy();
          this.loop();
        })
        .catch((error) => {
          if (error instanceof ContentNotFoundError) {
            this.$router.replace({ name: "WebsiteError" });
            return;
          } else if (error instanceof WebsiteNotFoundError) {
            this.$router.replace({ name: "Error" });
            return;
          }

          this.pageStatus = "error";

          this.timer.tryingSetup = window.setTimeout(() => {
            this.setup();
          }, 30 * 1000);
        });
    },
    loop(): void {
      this.timer.fetchingVacancies = setInterval(async () => {
        await this.fetchVacancy();
        await this.fetchHotSpringSetting();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearInterval(this.timer.fetchingVacancies);
      }
    },
    async fetchVacancy(): Promise<void> {
      try {
        const vacancyList: Vacancy[] = await fetchVacancyListByPlaceIdHashList([this.placeIdHash]);
        this.lastFetchedVacancyUnixTime = dayjs().unix();
        this.$store.commit("setAlertView", {
          status: false,
        });

        const vacancy: Vacancy | undefined = vacancyList.shift();
        if (vacancy !== undefined) {
          this.vacancy = vacancy;
        }
      } catch {
        const NETWORK_ERROR_THRESHOLD = 0.2 * 60;
        const currentUnixTime = dayjs().unix();
        if (currentUnixTime - NETWORK_ERROR_THRESHOLD >= this.lastFetchedVacancyUnixTime) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    async fetchHotSpringSetting(): Promise<void> {
      const hotSpringSettingList: HotSpringSetting[] | undefined = await fetchResponseHotSpringValuesByPlaceIdHashList([
        this.placeIdHash,
      ]);
      const hotSpringSetting: HotSpringSetting | undefined = hotSpringSettingList?.shift();
      if (hotSpringSetting !== undefined) {
        this.hotSpringSetting = hotSpringSetting;
      }
    },
  },
});
