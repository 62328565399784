
import Vue from "vue";

import { Place as BackendPlace, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { locationByPlaceIdHash } from "@/plugins/support";

import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import NaviButton from "@/components/place/navi-button.vue";
import LinkCopyButton from "@/components/place/link-copy-button.vue";
import LinkCopyToast from "@/components/place/link-copy-toast.vue";

type Equipment = BackendPlace.Equipment;
type HotSpringSettingValue = Http.HotSpringSettingValue;

export default Vue.extend({
  name: "panel-summary",
  components: {
    LabelSet,
    VacancyText,
    NaviButton,
    LinkCopyButton,
    LinkCopyToast,
  },
  props: {
    placeIdHash: { type: String, required: true },
    floor: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "-" },
    categoryList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    // vacancy-text props
    vacancyStatus: { type: String, required: false, default: "closed" },
    vacancyText: { type: String, required: false, default: "-" },
    // label-set props
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    // feature props
    featureList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    // onsen props
    hotSpringOpeningHoursList: {
      type: Array as () => HotSpringSettingValue[],
      required: false,
      default: (): HotSpringSettingValue[] => [],
    },
    currentGender: { type: String, required: false, default: undefined },
    // display settings
    isDisplayBusinessHour: { type: Boolean, default: true },
  },
  data() {
    return {
      linkCopied: false,
    };
  },
  computed: {
    showFloor(): boolean {
      return false; // This is intended for ise only.
    },
    showCategoryList(): boolean {
      return this.categoryList.length > 0;
    },
    lat(): number | undefined {
      return locationByPlaceIdHash(this.placeIdHash)?.lat;
    },
    lng(): number | undefined {
      return locationByPlaceIdHash(this.placeIdHash)?.lng;
    },
  },
  methods: {
    onShowForecast() {
      this.$gtag.event("Show Forecast in detail");
    },
  },
});
