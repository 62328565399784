import { fetchPlaceDetailByPlaceIdHash, fetchPlaceListByWebsiteId } from "@/api/enterprise-vacan.adapter.api";
import { Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";

Vue.use(Vuex);

interface Website {
    id: string;
    name: string;
    questionnaireUrl: string;
    theme: string;
    filtering: boolean;
}

type AlertView = {
    status: boolean;
    msg: string;
    btnTitle: string;
};

interface AppState {
    website: Website;
    placeList: Place.Place[];
    placeDetail: Record<string, Place.PlaceDetail>;
    alertView: AlertView;
}

const state: AppState = {
    website: {
        id: "",
        name: "",
        questionnaireUrl: "",
        theme: "white",
        filtering: false,
    },
    placeList: [],
    placeDetail: {},
    alertView: {
        status: false,
        msg: "",
        btnTitle: "",
    },
};

const mutations: MutationTree<AppState> = {
    setWebsite(state: AppState, website: Website) {
        state.website = website;
    },
    setPlaceList(state: AppState, placeList: Place.Place[]) {
        state.placeList = placeList;
    },
    setPlaceDetail(state: AppState, placeDetail: Place.PlaceDetail) {
        state.placeDetail[placeDetail.placeIdHash] = placeDetail;
    },

    setPageTheme(state: AppState, theme: string) {
        state.website.theme = theme;
    },
    setAlertView: (
        state: AppState,
        payload: {
            status: boolean;
            msg: string;
            btnTitle: string;
        },
    ) => {
        state.alertView = payload;
    },
};

const getters: GetterTree<AppState, AppState> = {
    getWebsite(state: AppState) {
        return state.website;
    },
    getPlaceList(state: AppState) {
        return state.placeList;
    },
    getPlaceDetail(state: AppState) {
        return (placeIdHash: string): Place.PlaceDetail | undefined => state.placeDetail[placeIdHash];
    },
};

const actions: ActionTree<AppState, AppState> = {
    async fetchWebsite({ commit }, websiteId: string) {
        const placeList = await fetchPlaceListByWebsiteId(websiteId);
        if (!placeList) {
            return;
        }
        commit("setWebsite", {
            id: websiteId,
            name: placeList.websiteTitle.name,
            questionnaireUrl: placeList.questionnaireUrl || "",
            theme: placeList.theme || "white",
            filtering: placeList.filtering,
            isAvailable: placeList.isAvailable ?? true,
        });
        commit("setPlaceList", placeList.placeList);
    },
    async fetchPlaceDetail({ commit }, params: { websiteId: string; placeIdHash: string }) {
        const placeDetail = await fetchPlaceDetailByPlaceIdHash(params.websiteId, params.placeIdHash);
        commit("setPlaceDetail", placeDetail);
        commit("setPageTheme", placeDetail.theme);
    },
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {},
});
